import { useDiscountService } from '../../../services/types.service';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FloatButton } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import AddDiscountModal from './AddDiscountModal';
import { DiscountType } from '../../../services/discount.service';

export default function AddDiscountButton() {
    const discountService = useDiscountService();
    const queryClient = useQueryClient();

    const addDiscountMutation = useMutation({
        mutationFn: async ({ name, type, amount }: {
            name: string,
            type: DiscountType,
            amount: number
        }) => await discountService.createDiscount(name, type, amount),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['discounts'] });
        }
    });

    return <>
        <FloatButton
            icon={<PercentageOutlined/>}
            onClick={() => NiceModal.show(AddDiscountModal, {
                onOk: (values) => addDiscountMutation.mutate(values)
            })}/>
    </>;
}
