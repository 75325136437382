import { useNotificationsService } from '../services/types.service';
import { useEffect } from 'react';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';

export default function Notifications() {
    const notificationsService = useNotificationsService();

    useEffect(() => {
        const notificationsSubscribe = notificationsService.notificationsSubject().subscribe(req => {
            if (req !== null) {
                enqueueSnackbar(req.message, { variant: req.variant });
            }
        });

        return () => {
            notificationsSubscribe.unsubscribe();
        };
    }, [notificationsService]);

    return <></>;
}
