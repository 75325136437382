import RegistrationForm from './RegistrationForm';
import { Flex, Typography } from 'antd';
import React from 'react';
import { Meal } from '../../services/meal.service';
import dayjs from 'dayjs';

interface Props {
    meal: Meal
}

export default function RegistrationLayout({ meal }: Props) {
    return <Flex vertical>
        <Typography.Title level={3} style={{ textAlign: "center", marginTop: "0.5em" }}>Регистрация на {meal.name} ({dayjs(meal.date).format('L')})</Typography.Title>
        <RegistrationForm mealId={meal.id}/>
    </Flex>;
}
