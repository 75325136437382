import RegistrationLayout from '../../components/registrations/RegistrationLayout';
import React from 'react';
import { useMealService } from '../../services/types.service';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Alert, Spin } from 'antd';

export default function RegistrationPage() {
    const mealService = useMealService();
    const mealId = Number(useParams().id) ?? 0;
    const { data: meal, status, error } = useQuery({
        queryKey: ['meal', mealId],
        queryFn: async () => {
            return mealService.getMealById(Number(mealId));
        },
    });

    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    }

    return <div style={{
        margin: '8px 16px',
        height: '100%'
    }}>
        <RegistrationLayout meal={meal}/>
    </div>;
}
