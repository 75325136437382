import { AllRegistrationsInfo, UserRegistrationInfo } from '../../../services/meal.service';
import { Badge, Button, Checkbox, Divider, Flex, Select, Space, Table, Typography } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import TotalSeatsStats from './TotalSeatsStats';
import { useAddPaymentMutation } from '../../../hooks/payments.hooks';
import NiceModal from '@ebay/nice-modal-react';
import { CommentOutlined, PayCircleOutlined } from '@ant-design/icons';
import { useRoleCheck } from '../../../hooks/auth.hooks';
import AddPaymentModal, { AddPaymentModalFormValues } from '../debts/AddPaymentModal';
import RegistrationCommentsModal from '../registrations/RegistrationCommentsModal';
import { useDiscountService, useMealService } from '../../../services/types.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Discount } from '../../../services/discount.service';
import _ from 'lodash';

interface ShabbatInfoProps {
    info: AllRegistrationsInfo;
    mealId: number;
}

function getColumns(mealId: number,
                    onPaymentAdded: (values: AddPaymentModalFormValues) => void,
                    hasEditRole: boolean): ColumnsType<UserRegistrationInfo> {
    return [
        { title: 'Телефон', dataIndex: 'phone', key: 'phone' },
        {
            title: 'Имя',
            dataIndex: '',
            render: value => `${value.firstName} ${value.lastName}`,
        },
        { title: 'Всего человек', dataIndex: 'totalPeople', key: 'totalPeople' },
        { title: 'Стоимость трапезы', dataIndex: 'price', key: 'price' },
        { title: 'Долг на момент регистрации', dataIndex: 'startingDebt', key: 'startingDebt' },
        { title: 'Оплачено', dataIndex: 'totalPayment', key: 'totalPayment' },
        { title: 'Остаток к оплате', dataIndex: 'totalDebt', key: 'totalDebt' },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            width: '5%',
            align: 'center',
            render: (value) => <Flex>
                <Badge count={value.commentsNumber}
                       size="small">
                    <Button
                        type="text"
                        shape="circle"
                        onClick={() => NiceModal.show(RegistrationCommentsModal, {
                            registrationId: value.id,
                            onOk: () => {}
                        })}
                        icon={<CommentOutlined/>}/>
                </Badge>
                {hasEditRole && <Button
                    type="text"
                    shape="circle"
                    onClick={() => NiceModal.show(AddPaymentModal, {
                        initialValues: { phone: value.phone, mealId },
                        onOk: onPaymentAdded
                    })}
                    icon={<PayCircleOutlined/>}/>}
            </Flex>,
        }
    ] as ColumnsType<UserRegistrationInfo>;
}

function RegistrationToExpandableRow({ registration, onToggle, discounts, onChangeDiscount }: {
    registration: UserRegistrationInfo,
    discounts: Discount[],
    onToggle: (id: number) => Promise<void>,
    onChangeDiscount: (personId: number, discountId: number) => Promise<void>
}) {
    const discountsOptions = _.concat([{
        label: 'Без скидки',
        value: 0
    }], discounts.map(d => ({
        label: d.name,
        value: d.id
    })));

    return <Flex vertical gap={4}>
        {registration.people.map(p => <Space size="small" align="center">
            <Checkbox onChange={() => onToggle(p.id)}
                      checked={p.enabled}></Checkbox>
            <Typography.Text type="secondary">{p.name}</Typography.Text>
            <Typography.Text type="secondary">€{p.price}</Typography.Text>
            <Select placeholder="Скидка"
                    defaultValue={p.discountId}
                    options={discountsOptions}
                    onSelect={d => onChangeDiscount(p.id, d)}/>
        </Space>)}
    </Flex>;
}

function RegistrationSummary({ info }: { info: AllRegistrationsInfo }) {
    let peopleCount = info.totalSeats;
    let totalPrice = info.totalPrice;
    let totalPayment = info.totalPayment;
    return <><Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong>Всего</Typography.Text></Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
            <Typography.Text strong>{peopleCount}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3} colSpan={2}>
            <Typography.Text strong>{totalPrice}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
            <Typography.Text strong>{totalPayment}</Typography.Text>
        </Table.Summary.Cell>
    </Table.Summary.Row>
    </>;
}

export default function ShabbatInfo({ info, mealId }: ShabbatInfoProps) {
    const addPaymentMutation = useAddPaymentMutation();
    const mealService = useMealService();
    const discountService = useDiscountService();
    const canEditPayment = useRoleCheck('WRITE_PAYMENTS');
    const queryClient = useQueryClient();

    const { data: discounts } = useQuery({
        queryKey: ['discounts'],
        queryFn: async () => {
            return discountService.getDiscounts();
        },
    });

    const toggleMutation = useMutation({
        mutationFn: async (personId: number) => {
            await mealService.togglePersonRegistration(personId);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['debts'] });
            queryClient.invalidateQueries({ queryKey: ['current'] });
            queryClient.invalidateQueries({ queryKey: ['meal', 'registrations', mealId] });
        }
    });

    const changeDiscount = useMutation({
        mutationFn: async ({ personId, discountId }: { personId: number, discountId: number }) => {
            await mealService.setRegistrationDiscount(personId, discountId);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['debts'] });
            queryClient.invalidateQueries({ queryKey: ['current'] });
            queryClient.invalidateQueries({ queryKey: ['meal', 'registrations', mealId] });
        }
    });

    console.dir(info);

    return <Flex vertical gap={0}>
        <TotalSeatsStats total={info.totalSeats}/>
        <Divider/>
        <Table
            columns={getColumns(mealId,
                values => addPaymentMutation.mutate(values),
                canEditPayment)}
            dataSource={info.registrations}
            rowKey={record => record.id}
            summary={() => <RegistrationSummary info={info}/>}
            expandable={{
                expandedRowRender: r => <RegistrationToExpandableRow registration={r}
                                                                     discounts={discounts ?? []}
                                                                     onToggle={async (id) => toggleMutation.mutate(id)}
                                                                     onChangeDiscount={async (personId, discountId) => changeDiscount.mutate({
                                                                         personId,
                                                                         discountId
                                                                     })}/>,
                expandRowByClick: true,
                showExpandColumn: false,
            }}
            scroll={{
                x: true
            }}/>
    </Flex>;
};
