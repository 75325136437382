import { BaseVariant } from 'notistack';
import { BehaviorSubject } from 'rxjs';

export interface NotificationRequest {
    message: string;
    variant: BaseVariant;
}

export interface INotificationsService {
    notificationsSubject(): BehaviorSubject<NotificationRequest | null>

    notify(message: string): void,

    error(message: string): void,

    success(message: string): void,

    warning(message: string): void,

    info(message: string): void,
}

export class NotificationsService implements INotificationsService {
    public notification$ = new BehaviorSubject<NotificationRequest | null>(null);

    private sendNotification(message: string, variant: BaseVariant): void {
        this.notification$.next({ message, variant });
    }

    notify(message: string): void {
        this.sendNotification(message, 'default');
    }

    error(message: string): void {
        this.sendNotification(message, 'error');
    }

    success(message: string): void {
        this.sendNotification(message, 'success');
    }

    warning(message: string): void {
        this.sendNotification(message, 'warning');
    }

    info(message: string): void {
        this.sendNotification(message, 'info');
    }

    public notificationsSubject(): BehaviorSubject<NotificationRequest | null> {
        return this.notification$;
    }
}
