import { Discount } from '../../../services/discount.service';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import React from 'react';
import i18n from 'i18next';

interface Props {
    discounts: Array<Discount>;
}

function getColumns(): ColumnsType<Discount> {
    return [
        { title: 'Название', dataIndex: 'name', key: 'name' },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            render: (value) => {
                return i18n.t(`DISCOUNT_${value}`);
            }
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount',
        }
    ];
}

export default function DiscountsList({ discounts }: Props) {
    return <Table
        columns={getColumns()}
        rowKey={discount => discount.id}
        dataSource={discounts}
        scroll={{
            x: true
        }}/>;
}
