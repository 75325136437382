import { useQuery } from '@tanstack/react-query';
import { useMealService } from '../../services/types.service';
import { Alert, Flex, Spin, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { Meal } from '../../services/meal.service';

interface Props {
    render: (m: Meal) => ReactNode;
}

export default function FetchAllFutureMeals({ render }: Props) {
    const mealService = useMealService();

    const { data: meals, status, error } = useQuery({
        queryKey: ['futureMeals'],
        queryFn: async () => {
            return await mealService.getFutureMeals();
        }
    });

    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    }

    return <Flex gap={10}
                 wrap="nowrap"
                 style={{
                     overflowX: 'auto',
                     overflowY: 'hidden',
                     scrollBehavior: 'auto',
                 }}>
        {meals?.length > 0 ? meals.map(m => render(m)) :
            <Typography.Title level={5}
                              style={{ width: '100%', marginTop: '0.5em', textAlign: 'center', color: 'white' }}>В
                ближайшее
                время трапез
                нет</Typography.Title>}
    </Flex>;
}
