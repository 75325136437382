import { DatePicker, Form, Input, Modal } from 'antd';
import React from 'react';
import useModalForm from '../../../hooks/modal.hooks';
import NiceModal from '@ebay/nice-modal-react';

interface Props {
    onOk: (values: FormValues) => void;
}

interface FormValues {
    name: string;
    date: string;
}

const AddMealModal = NiceModal.create(({ onOk }: Props) => {
    const { form, submit, cancel, visible } = useModalForm<FormValues>({ onOk });

    return <Modal
        centered
        title="Добавить трапезу"
        onOk={() => submit()}
        onCancel={cancel}
        open={visible}
        destroyOnClose>
        <Form form={form}>
            <Form.Item name="name">
                <Input placeholder="Название трапезы"/>
            </Form.Item>
            <Form.Item name="date">
                <DatePicker placeholder="Дата трапезы"/>
            </Form.Item>
        </Form>
    </Modal>;
});

export default AddMealModal;
