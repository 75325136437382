import { useMeService } from '../../services/types.service';
import { useQuery } from '@tanstack/react-query';
import { Alert, Flex, Spin, Typography } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

interface DebtEntry {
    amount: number,
    name: string
}

function UserDebtEntry({ entry }: { entry: DebtEntry }) {
    return <Flex justify="space-around" align="center">
        <Typography.Text strong
                         style={{
                             width: '100%',
                             textAlign: 'start',
                             verticalAlign: 'center',
                             color: 'white'
                         }}>{entry.name}</Typography.Text>
        <Typography.Text strong style={{
            width: '100%',
            textAlign: 'end',
            verticalAlign: 'center',
            color: 'white'
        }}>{`€${entry.amount}`}</Typography.Text>
    </Flex>;
}

export default function UserDebtOverview() {
    const meService = useMeService();

    const { data: debtInfo, status, error } = useQuery({
        queryKey: ['my_debts'], queryFn: async () => {
            return await meService.getDebt();
        },
        retry: false
    });
    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    }
    return <Flex vertical>
        <Flex justify="space-around" align="center">
            <Typography.Title level={5}
                              style={{
                                  width: '100%',
                                  textAlign: 'start',
                                  verticalAlign: 'center',
                                  color: 'white',
                                  marginTop: '0.5em',
                              }}>Суммарный долг</Typography.Title>
            <Typography.Text strong style={{
                width: '100%',
                textAlign: 'end',
                verticalAlign: 'center',
                color: 'white'
            }}>{`€${debtInfo.total ?? 0}`}</Typography.Text>
        </Flex>
        {debtInfo?.debts.map(value =>
            <UserDebtEntry entry={{ ...value, name: dayjs(value.date).format('L') }}/>)}
    </Flex>;
}
