import type { INetworkingService } from './networking.service';

export interface AgeCategory {
    id: number;
    name: string;
    basePrice: number;
}

export default interface IAgeCategoryService {
    getAllCategories(): Promise<AgeCategory[]>;
    createNewCategory(name: string, basePrice: number): Promise<AgeCategory[]>;
    deleteCategory(id: number): Promise<void>;
}

export class AgeCategoryService implements IAgeCategoryService {
    constructor(private api: INetworkingService) {}

    public createNewCategory(name: string, basePrice: number): Promise<AgeCategory[]> {
        return this.api.post(`/age_category`, { name, basePrice }, { withAuth: true })
    }

    public deleteCategory(id: number): Promise<void> {
        return this.api.delete(`/age_category/${id}`, {}, { withAuth: true });
    }

    public getAllCategories(): Promise<AgeCategory[]> {
        return this.api.get(`/age_category`, {}, { withAuth: true });
    }
}
