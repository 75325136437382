import { useDiscountService } from '../../../services/types.service';
import { useQuery } from '@tanstack/react-query';
import { Alert, Empty, Spin } from 'antd';
import React from 'react';
import DiscountsList from './DiscountsList';

export default function FetchAllDiscounts() {
    const discountService = useDiscountService();
    const { data: discounts, status, error } = useQuery({
        queryKey: ['discounts'],
        queryFn: async () => {
            return discountService.getDiscounts();
        },
    });

    console.table(discounts);

    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    } else if ((discounts?.length ?? 0) < 1) {
        return <Empty description="Не найдена ни одна скидка"/>;
    }
    return <DiscountsList discounts={discounts}/>;
}
