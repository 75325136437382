import { useAgeCategoryService } from '../../../services/types.service';
import { useQuery } from '@tanstack/react-query';
import { Alert, Empty, Spin } from 'antd';
import React from 'react';
import AgeCategoriesList from './AgeCategoriesList';

export default function FetchAllAgeCategories() {
    const ageCategoryService = useAgeCategoryService();
    const { data: ageCategories, status, error } = useQuery({
        queryKey: ['discounts'],
        queryFn: async () => {
            return ageCategoryService.getAllCategories();
        },
    });

    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    } else if ((ageCategories?.length ?? 0) < 1) {
        return <Empty description="Не найдена ни одна возрастная категория"/>;
    }
    return <AgeCategoriesList ageCategories={ageCategories}/>;
}
