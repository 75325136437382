import type { INetworkingService } from './networking.service';
import IStorageService from './storage.service';

export interface ICorrectionsService {
    createCorrection(registrationId: number, adults: number, juniors: number, kids: number): Promise<void>;
}

export class CorrectionsService implements ICorrectionsService {
    constructor(private api: INetworkingService) {
    }

    public async createCorrection(registrationId: number, adults: number, juniors: number, kids: number): Promise<void> {
        return await this.api.post(`/registrations/${registrationId}/corrections`, {
            adults, juniors, kids
        }, { withAuth: true });
    }
}
