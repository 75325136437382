import { Discount } from '../../../services/discount.service';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import React from 'react';
import { AgeCategory } from '../../../services/age_category';

interface Props {
    ageCategories: Array<AgeCategory>;
}

function getColumns(): ColumnsType<AgeCategory> {
    return [
        { title: 'Название', dataIndex: 'name', key: 'name' },
        {
            title: 'Сумма',
            dataIndex: 'basePrice',
            key: 'basePrice',
        }
    ];
}

export default function AgeCategoriesList({ ageCategories }: Props) {
    return <Table
        columns={getColumns()}
        rowKey={category => category.id}
        dataSource={ageCategories}
        scroll={{
            x: true
        }}/>;
}
