import { useAuthService, useNotificationsService } from '../../services/types.service';
import { useAuth } from '../../hooks/auth.hooks';
import React, { useState } from 'react';
import { Button, Flex, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

export default function SignUpForm() {
    const authService = useAuthService();
    const notificationsService = useNotificationsService();
    const { invalidateAuth } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const onLogin = (values: any) => {
        setIsLoading(true);
        const phone = values.phone;
        const password = values.password;
        const firstName = values.firstName;
        const lastName = values.lastName;
        authService.signUp(phone, password, firstName, lastName)
            .then(() => invalidateAuth())
            .then(() => {
                setIsLoading(false);
            })
            .catch((e) => {
                notificationsService.error(`Ошибка регистрации ${e.toString()}`);
                setIsLoading(false);
            });
    };

    return <Flex justify="center" align="center" style={{ height: '100%' }}>
        <Form
            name="normal_login"
            className="login-form"
            onFinish={onLogin}
        >
            <Form.Item
                name="phone"
                rules={[{ required: true, message: 'Пожалуйста, введите ваш номер телефона!' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Номер телефона"/>
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Пароль"
                />
            </Form.Item>
            <Form.Item
                name="firstName"
                rules={[{ required: true, message: 'Пожалуйста, введите ваше имя' }]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon"/>}
                    placeholder="Имя"
                />
            </Form.Item>
            <Form.Item
                name="lastName"
                rules={[{ required: true, message: 'Пожалуйста, введите вашу фамилию' }]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon"/>}
                    placeholder="Фамилия"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{ width: "100%" }}
                        loading={isLoading}>
                    Создать учетную запись
                </Button>
            </Form.Item>
        </Form>
    </Flex>;
}
