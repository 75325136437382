import { Flex, Table, Typography } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

interface DebtInfo {
    phone: string,
    name: string,
    total: number,
    debts: Array<{
        amount: number;
        date: number;
    }>
}

interface CurrentDebtsInfoProps {
    debts: Array<DebtInfo>;
}

const columns: ColumnsType<DebtInfo> = [
    { title: 'Телефон', dataIndex: 'phone', key: 'phone' },
    { title: 'Имя фамилия', dataIndex: 'name', key: 'name' },
    { title: 'Остаток к оплате', dataIndex: 'total', key: 'total' },
];

function RegistrationToExpandableRow({ debtInfo }: { debtInfo: DebtInfo }) {
    return <Flex vertical>{debtInfo.debts.map(debt => (
        <Flex justify="space-around" align="center">
            <Typography.Text strong
                             style={{
                                 width: '100%',
                                 textAlign: 'start',
                                 verticalAlign: 'center'
                             }}>{dayjs(debt.date).format('DD.MM.YYYY')}</Typography.Text>
            <Typography.Text strong style={{
                width: '100%',
                textAlign: 'end',
                verticalAlign: 'center'
            }}>{`€${debt.amount}`}</Typography.Text>
        </Flex>
    ))}
    </Flex>;
}

export default function CurrentDebtsInfo({ debts }: CurrentDebtsInfoProps) {
    return <Table columns={columns}
                  rowKey={record => record.phone}
                  dataSource={debts}
                  expandable={{
                      expandedRowRender: d => <RegistrationToExpandableRow debtInfo={d}/>,
                      expandRowByClick: true,
                      showExpandColumn: false,
                  }}/>;
};
