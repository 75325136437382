import { FloatButton } from 'antd';
import React from 'react';
import AddPaymentButton from './debts/AddPaymentButton';
import { PlusOutlined } from '@ant-design/icons';
import { useRoleCheck } from '../../hooks/auth.hooks';
import AddMealButton from './meals/AddMealButton';
import AddDiscountButton from './discounts/AddDiscountButton';
import AddAgeCategoryButton from './age_categories/AddAgeCategoryButton';

export default function FloatingMenuButton() {
    const canAddPayments = useRoleCheck('WRITE_PAYMENTS');
    const canAddMeals = useRoleCheck('WRITE_MEALS');
    const canAddDiscounts = useRoleCheck('WRITE_DISCOUNTS');
    const canAddAgeCategories = useRoleCheck('WRITE_CATEGORIES');
    return <FloatButton.Group
        trigger="hover"
        type="primary"
        icon={<PlusOutlined/>}>
        {canAddPayments && <AddPaymentButton/>}
        {canAddMeals && <AddMealButton/>}
        {canAddDiscounts && <AddDiscountButton/>}
        {canAddAgeCategories && <AddAgeCategoryButton/>}
    </FloatButton.Group>;
}
