import { createContext, useContext } from 'react';

export function createDependencyContext<T>() {
    const context = createContext<T | null>(null);
    const useDependency = () => {
        const dep = useContext(context);
        if (!dep) {
            throw new Error('useDependency hook must be used within an dependency provider');
        }
        return dep;
    };
    return { context, useDependency };
}
