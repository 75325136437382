import React from 'react';
import { Flex } from 'antd';
import { useAuth, useRoleCheck } from '../../hooks/auth.hooks';
import DashboardSection from '../../components/user_dashboard/DashboardSection';
import { useNavigate } from 'react-router-dom';
import FetchAllFutureMeals from '../../components/user_dashboard/FetchAllFutureMeals';
import FutureMealCard from '../../components/user_dashboard/FutureMealCard';
import UserDebtOverview from '../../components/user_dashboard/UserDebtOverview';

export default function UserDashboard() {
    const user = useAuth();
    const navigate = useNavigate();
    const isAdmin = useRoleCheck('ADMIN');

    return <Flex style={{
        margin: '8px 16px',
        height: '100%'
    }} gap={16} vertical>
        <DashboardSection
            title={`Привет, ${user.user?.name ?? 'пользователь'} 👋`}
            backgroundColor="transparent"
            titleColor="black"
        />
        {isAdmin ? <DashboardSection
            title={`К панели администратора`}
            withArrow
            onClick={() => {
                navigate('/admin', { replace: false });
            }}
            backgroundColor="#831330"
            titleColor="white"
        /> : null}
        <Flex vertical gap={4}>
            <DashboardSection
                title={`Ближайшие трапезы`}
                backgroundColor="#831330"
                titleColor="white"
            >
                <div style={{ marginTop: "0.5em" }}>
                <FetchAllFutureMeals
                    render={m => <FutureMealCard meal={m}
                                                 onClick={m => navigate(`/registration/${m.id}`, { replace: false })}/>}/>
                </div>
            </DashboardSection>
            <DashboardSection
                title={`Отсроченные платежи за прошедшие трапезы`}
                backgroundColor="#831330"
                titleColor="white"
            >
                <UserDebtOverview/>
            </DashboardSection>
        </Flex>
    </Flex>;
}
