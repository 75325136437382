import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './localizations/en.json';
import ru from './localizations/ru.json';
import ua from './localizations/ua.json';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en, ru, ua
        },
        fallbackLng: 'ru'
    });
