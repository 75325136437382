import { useMealService } from '../../../services/types.service';
import { useQuery } from '@tanstack/react-query';
import { Alert, Empty, Spin } from 'antd';
import React from 'react';
import MealsList from './MealsList';

export default function FetchAllMeals() {
    const mealService = useMealService();
    const { data: meals, status, error } = useQuery({
        queryKey: ['meals'],
        queryFn: async () => {
            return mealService.getAllMeals();
        },
    });

    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    } else if ((meals?.length ?? 0) < 1) {
        return <Empty description="Не найдена ни одна трапеза"/>;
    }
    return <MealsList meals={meals}/>;
}
