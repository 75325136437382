import { Axios, RawAxiosRequestHeaders } from 'axios';
import type IStorageService from './storage.service';
import { captureException, withScope } from '@sentry/react';

interface RequestConfig {
    withAuth?: boolean;
}

export interface INetworkingService {
    get<Response>(path: string, params: Record<string, any>, config: RequestConfig): Promise<Response>;

    getBlob(path: string, params: Record<string, any>, config: RequestConfig): Promise<Blob>;

    post<Response, Request>(path: string, body: Request, config: RequestConfig): Promise<Response>;

    put<Response, Request>(path: string, body: Request, config: RequestConfig): Promise<Response>;

    delete<Response>(path: string, params: Record<string, any>, config: RequestConfig): Promise<Response>;
}

export class NetworkingError extends Error {
    constructor(public path: string,
                public body: Record<string, any> | any,
                public config: RequestConfig,
                public reason: any) {super();}
}

export class FetchNetworkingService implements INetworkingService {
    constructor(protected basePath: string) {}

    public async get<Response>(path: string, params: Record<string, any>, config: RequestConfig = { withAuth: true }): Promise<Response> {
        const urlSearchParams = new URLSearchParams(params);
        const url = new URL(`${this.basePath}${path}`);
        url.search = urlSearchParams.toString();
        const response = await fetch(url, {
            // mode: 'no-cors'
        });
        return response.json() as Promise<Response>;
    }

    async getBlob(path: string, params: Record<string, any>, config: RequestConfig = { withAuth: true }): Promise<Blob> {
        const urlSearchParams = new URLSearchParams(params);
        const url = new URL(`${this.basePath}${path}`);
        url.search = urlSearchParams.toString();
        const response = await fetch(url, {
            // mode: 'no-cors'
        });
        return response.blob();
    }

    public async post<Response, Request>(path: string, body: Request, config: RequestConfig = { withAuth: true }): Promise<Response> {
        const response = await fetch(path, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json() as Promise<Response>;
    }

    public async put<Response, Request>(path: string, body: Request, config: RequestConfig): Promise<Response> {
        const response = await fetch(path, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json() as Promise<Response>;
    }

    public async delete<Response>(path: string, params: Record<string, any>, config: RequestConfig): Promise<Response> {
        const urlSearchParams = new URLSearchParams(params);
        const url = new URL(`${this.basePath}${path}`);
        url.search = urlSearchParams.toString();
        const response = await fetch(url, {
            method: 'DELETE'
            // mode: 'no-cors'
        });
        return response.json() as Promise<Response>;
    }
}

export class AxiosNetworkingService implements INetworkingService {
    constructor(private client: Axios, private storage: IStorageService) {}

    private getToken(): string {
        const token = this.storage.get('token');
        return token !== null ? `Bearer ${token}` : '';
    }

    private getHeadersByConfig(config: RequestConfig): RawAxiosRequestHeaders {
        let headers: RawAxiosRequestHeaders = {};
        if (config.withAuth) {
            headers.Authorization = this.getToken();
        }
        return headers;
    }

    public async get<T>(path: string, params: Record<string, any>, config: RequestConfig = { withAuth: true }): Promise<T> {
        return this.client.get(path, {
            params,
            headers: this.getHeadersByConfig(config)
        }).then(res => {
            if (res.status < 400) {
                return res.data;
            } else {
                const error = new NetworkingError(path, params, config, res.data);
                withScope(scope => {
                    scope.setContext('request', { ...error });
                    captureException(error);
                });
                throw error;
            }
        });
    }

    getBlob(path: string, params: Record<string, any>, config: RequestConfig = { withAuth: true }): Promise<Blob> {
        return this.client.get(path, {
            params,
            headers: this.getHeadersByConfig(config),
            responseType: 'blob'
        }).then(res => {
            if (res.status < 400) {
                return res.data;
            } else {
                const error = new NetworkingError(path, params, config, res.data);
                withScope(scope => {
                    scope.setContext('request', { ...error });
                    captureException(error);
                });
                throw error;
            }
        });
    }

    public async post<Response, Request>(path: string, body: Request, config: RequestConfig = { withAuth: true }): Promise<Response> {
        return await this.client.post(path, body, {
            headers: this.getHeadersByConfig(config)
        }).then(res => {
            if (res.status < 400) {
                return res.data;
            } else {
                const error = new NetworkingError(path, body, config, res.data);
                withScope(scope => {
                    scope.setContext('request', { ...error });
                    captureException(error);
                });
                throw error;
            }
        });
    }

    public async put<Response, Request>(path: string, body: Request, config: RequestConfig = { withAuth: true }): Promise<Response> {
        return await this.client.put(path, body, {
            headers: this.getHeadersByConfig(config)
        }).then(res => {
            if (res.status < 400) {
                return res.data;
            } else {
                const error = new NetworkingError(path, body, config, res.data);
                withScope(scope => {
                    scope.setContext('request', { ...error });
                    captureException(error);
                });
                throw error;
            }
        });
    }

    public async delete<Response>(path: string, params: Record<string, any>, config: RequestConfig): Promise<Response> {
        return this.client.delete(path, {
            params,
            headers: this.getHeadersByConfig(config)
        }).then(res => {
            if (res.status < 400) {
                return res.data;
            } else {
                const error = new NetworkingError(path, params, config, res.data);
                withScope(scope => {
                    scope.setContext('request', { ...error });
                    captureException(error);
                });
                throw error;
            }
        });
    }
}
