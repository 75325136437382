import { FloatButton } from 'antd';
import AddPaymentModal from './AddPaymentModal';
import React from 'react';
import { PayCircleOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useAddPaymentMutation } from '../../../hooks/payments.hooks';

export default function AddPaymentButton() {
    const addPaymentMutation = useAddPaymentMutation();

    return <>
        <FloatButton
            icon={<PayCircleOutlined/>}
            onClick={() => NiceModal.show(AddPaymentModal, {
                onOk: (values) => addPaymentMutation.mutate({
                    phone: values.phone,
                    payments: values.payments
                })
            })}/>
    </>;
}
