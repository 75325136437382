import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AddPaymentModalFormValues } from '../debts/AddPaymentModal';
import { Button, Flex, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCommentsService } from '../../../services/types.service';
import CommentDisplay from './CommentDisplay';
import TextArea from 'antd/lib/input/TextArea';

interface Props {
    onOk: (values: AddPaymentModalFormValues) => void;
    registrationId: number;
}

const RegistrationCommentsModal = NiceModal.create(({ onOk, registrationId }: Props) => {
    const modal = useModal();
    const commentsService = useCommentsService();
    const queryClient = useQueryClient();

    const [newCommentContent, setNewCommentContent] = useState('');

    const { data: comments } = useQuery({
        queryKey: ['comments', registrationId],
        queryFn: async () => {
            return commentsService.getCommentsForRegistration(registrationId);
        }
    });

    const addCommentMutation = useMutation({
        mutationFn: async (content: string) => {
            await commentsService.createComment(registrationId, content);
        },
        onSuccess: () => {
            setNewCommentContent('');
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['comments', registrationId] });
        }
    });

    return <Modal
        centered
        title="Комментарии к регистрации"
        open={modal.visible}
        onCancel={() => modal.remove()}
        footer={<></>}>
        <Flex vertical gap={6}>
            <Flex vertical gap={4} style={{
                overflowY: 'auto',
                maxHeight: '60vh'
            }}>
                {comments?.map(c => <CommentDisplay comment={c}/>)}
            </Flex>
            <Flex vertical gap={2}>
                <TextArea
                    maxLength={140}
                    placeholder="Добавить комментарий"
                    style={{ height: 60 }}
                    value={newCommentContent}
                    onChange={(e) => setNewCommentContent(e.target.value)}
                />
                <Button type="primary" onClick={() => addCommentMutation.mutate(newCommentContent)}>Добавить</Button>
            </Flex>
        </Flex>
    </Modal>;
});

export default RegistrationCommentsModal;
