import { Form, Input, Modal } from 'antd';
import React from 'react';
import { User } from '../../../services/user.service';
import useModalForm from '../../../hooks/modal.hooks';
import NiceModal from '@ebay/nice-modal-react';

interface Props {
    onOk: (values: FormValues) => void;
    user: User;
}

interface FormValues {
    id: number;
    phone: string;
    firstName: string;
    lastName: string;
}

const UpdateUserModal = NiceModal.create(({ onOk, user }: Props) => {
    const { form, submit, cancel, visible } = useModalForm<FormValues>({ onOk });

    return <Modal
        centered
        title="Изменить данные пользователя"
        onOk={() => submit()}
        onCancel={() => cancel()}
        open={visible}
        destroyOnClose>
        <Form form={form} initialValues={user}>
            <Form.Item name="id" hidden/>
            <Form.Item name="phone">
                <Input placeholder="Номер телефона"/>
            </Form.Item>
            <Form.Item name="firstName">
                <Input placeholder="Имя"/>
            </Form.Item>
            <Form.Item name="lastName">
                <Input placeholder="Фамилия"/>
            </Form.Item>
        </Form>
    </Modal>;
});

export default UpdateUserModal;
