import { Form } from 'antd';
import { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';

interface FormProps<FormValues> {
    onOk: (values: FormValues) => void;
}

export default function useModalForm<FormValues>({ onOk }: FormProps<FormValues>) {
    const [form] = Form.useForm<FormValues>();
    const modal = useModal();

    const submit = useCallback(() => {
        const values = form.getFieldsValue();
        onOk(values);
        modal.remove();
    }, [form, modal, onOk]);

    const cancel = useCallback(() => {
        modal.remove();
    }, [modal]);

    return {
        form, submit, cancel, visible: modal.visible
    };
}
