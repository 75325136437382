import { useUserService } from '../../../services/types.service';
import { useQuery } from '@tanstack/react-query';
import { Alert, Spin } from 'antd';
import React from 'react';
import CurrentDebtsInfo from './CurrentDebtsInfo';

export default function FetchCurrentDebts() {
    const userService = useUserService();
    const { data: debts, status, error } = useQuery({
        queryKey: ['current_debts'],
        queryFn: async () => {
            return userService.getAllCurrentDebts();
        },
        select: data => data.map(debt => ({
            phone: debt.user.phone,
            name: `${debt.user.firstName} ${debt.user.lastName}`,
            debts: debt.debts,
            total: debt.total
        }))
    });

    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    }
    return <CurrentDebtsInfo debts={debts}/>;
}
