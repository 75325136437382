import { AutoComplete, Button, Flex, Form, InputNumber, Modal, Select, Space, Typography } from 'antd';
import { useMealService, useUserService } from '../../../services/types.service';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import useModalForm from '../../../hooks/modal.hooks';
import NiceModal from '@ebay/nice-modal-react';
import dayjs from 'dayjs';
import { PaymentDescription } from '../../../services/payment.service';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface AddPaymentModalProps {
    onOk: (values: AddPaymentModalFormValues) => void;
    initialValues?: {
        mealId?: number;
        phone?: string;
    };
}

export interface AddPaymentModalFormValues {
    phone: string;
    payments: PaymentDescription[];
}

const AddPaymentModal = NiceModal.create(({ onOk, initialValues }: AddPaymentModalProps) => {
    const userService = useUserService();
    const mealService = useMealService();
    const { form, submit, cancel, visible } = useModalForm<AddPaymentModalFormValues>({ onOk });
    const currentFilter = Form.useWatch('phone', form);
    const [autocompleteOptions, setAutocompleteOptions] = useState<{ value: string }[]>([]);

    const { data: meals } = useQuery({
        queryKey: ['meals'],
        queryFn: async () => mealService.getAllMeals()
    });

    const { data: phones } = useQuery({
        queryKey: ['phones'],
        queryFn: async () => userService.getAllUsers(),
        select: data => data.map(user => ({ value: user.phone }))
    });

    useEffect(() => {
        if (currentFilter) {
            setAutocompleteOptions(phones?.filter(p => p.value.startsWith(currentFilter)) ?? []);
        } else {
            setAutocompleteOptions(phones ?? []);
        }
    }, [phones, currentFilter]);

    return <Modal
        centered
        title="Внести информацию об оплате"
        onOk={() => submit()}
        onCancel={cancel}
        open={visible}>
        <Form form={form}>
            <Form.Item name="phone" initialValue={initialValues?.phone ?? ''} style={{ marginBottom: 12 }}>
                <AutoComplete
                    placeholder="Номер телефона"
                    options={autocompleteOptions}/>
            </Form.Item>
            <Typography.Title level={5} style={{
                textAlign: 'center',
                marginTop: 0,
                marginBottom: 12,
            }}>Оплаты</Typography.Title>
            <Form.List name="payments" rules={[
                {
                    validator: async (_, payments) => {
                        if (!payments || payments.length < 1) {
                            return Promise.reject(new Error('Минимум одна оплата!'));
                        }
                    }
                }
            ]}>
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item {...restField} name={[name, 'mealId']}
                                           initialValue={initialValues?.mealId ?? ''}>
                                    <Select
                                        style={{ minWidth: '15em' }}
                                        placeholder="Трапеза"
                                        options={meals?.map(m => ({
                                            value: m.id,
                                            label: `${m.name} (${dayjs(m.date).format('L')})`
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item {...restField} name={[name, 'amount']}>
                                    <InputNumber
                                        placeholder="Сумма"/>
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(name)}
                                    />
                                ) : null}
                            </Space>
                        ))}
                        <Form.Item style={{ alignSelf: 'center', width: '100%' }}>
                            <Flex align="stretch" justify="center" style={{ width: '100%' }}>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined/>}
                                    style={{ width: "90%" }}
                                >
                                    Добавить оплату
                                </Button>
                            </Flex>
                        </Form.Item>
                    </>
                )}

            </Form.List>
        </Form>
    </Modal>;
});

export default AddPaymentModal;
;
