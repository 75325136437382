import React from 'react';
import { Flex, Typography } from 'antd';
import FetchAllUsers from '../../components/management/users/FetchAllUsers';

export default function AllUsersInfoPage() {
    return <Flex vertical>
        <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '0.5em' }}>Все
            пользователи</Typography.Title>
        <FetchAllUsers/>
    </Flex>;
}
