import FetchCurrentRegistrationsList from '../../components/management/shabbat/FetchCurrentRegistrationsList';
import React, { useCallback } from 'react';
import { Button, Flex, Typography } from 'antd';
import { useMealService } from '../../services/types.service';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

export default function CurrentShabbatInfoPage() {
    const mealService = useMealService();
    const mealId = Number(useParams().id) ?? 0;
    const { data: meal } = useQuery({
        queryKey: ['meal', mealId],
        queryFn: async () => {
            return mealService.getMealById(Number(mealId));
        },
        select: data => ({ name: data.name, date: data.date })
    });

    const downloadExcelTable = useCallback(async () => {
        const excelFileBlob = await mealService.getExcelRegistrationsFile(mealId);
        const url = URL.createObjectURL(excelFileBlob);
        window.location.assign(url);
    }, [mealService, mealId]);

    console.dir(meal);

    return <Flex vertical>
        <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '0.5em' }}>Регистрации на
            трапезу {meal?.name ?? ''} ({dayjs(meal?.date).format('L')})</Typography.Title>
        <FetchCurrentRegistrationsList/>
        <Button type="primary" size="large" style={{ width: '80%', alignSelf: 'center' }} onClick={downloadExcelTable}>Загрузить
            в виде таблицы</Button>
    </Flex>;
}
