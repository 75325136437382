import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Flex, Form, Input, InputNumber, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { useMealService } from '../../services/types.service';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { captureException, withScope } from '@sentry/react';
import { PersonRegistration } from '../../services/meal.service';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useImmerReducer } from 'use-immer';
import _ from 'lodash';
import useAgeCategoryOptions from '../../hooks/age_categories.hooks';
import { z } from 'zod';

interface Props {
    mealId: number;
}

const registrationFormSchema = z.object({
    mealId: z.number().min(1),
    people: z.array(z.object({
        firstName: z.string().trim().min(1),
        lastName: z.string().trim().min(1),
        ageCategory: z.number().min(1)
    })),
});

type RegistrationFormValues = z.infer<typeof registrationFormSchema>

type Action = { type: 'add' }
    | { type: 'remove', index: number }
    | { type: 'setFirstName', index: number, firstName: string }
    | { type: 'setLastName', index: number, lastName: string }
    | { type: 'setAgeCategory', index: number, ageCategory: number }

const formReducer = (values: RegistrationFormValues, action: Action) => {
    switch (action.type) {
        case 'add':
            values.people.push({
                firstName: '',
                lastName: '',
                ageCategory: 1
            });
            break;
        case 'remove':
            values.people = _.filter(values.people, (value, index) => index !== action.index);
            break;
        case 'setFirstName':
            values.people[action.index].firstName = action.firstName;
            break;
        case 'setLastName':
            values.people[action.index].lastName = action.lastName;
            break;
        case 'setAgeCategory':
            values.people[action.index].ageCategory = action.ageCategory;
            break;
    }
};

export default function RegistrationForm({ mealId }: Props) {
    const [form, dispatchToPeople] = useImmerReducer<RegistrationFormValues, Action>(formReducer, {
        mealId,
        people: []
    });

    const mealService = useMealService();
    const ageCategories = useAgeCategoryOptions();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['price'] });
    }, [form]);

    const submitRegistration = useMutation({
        mutationFn: async (registration: RegistrationFormValues) => {
            return await mealService.registerForMeal(mealId, registration.people);
        },
        onSuccess: () => {
            navigate('/result?success=true');
        },
        onError: (error: AxiosError) => {
            navigate(`/result?success=false&error=${(error.response?.data as any).message ?? ''}`);
            withScope(scope => {
                scope.addBreadcrumb({
                    type: 'request_error',
                    message: 'Error occured during request',
                    data: error.request
                });
                captureException(error);
            });
        }
    });

    const validateForm = (values: RegistrationFormValues) => {
        return registrationFormSchema.safeParse(values).success && values.people.length > 0;
    }

    const onSubmit = (values: RegistrationFormValues) => {
        submitRegistration.mutate(values);
    };

    return <Form
        layout="vertical"
        onFinish={onSubmit}
    >
        {form.people.map((person, index) => (
            <Space key={index} style={{ display: 'flex' }} align="baseline">
                <Form.Item rules={[{ required: true, message: "Пожалуйста, введите Ваше имя!" }]}>
                    <Input placeholder="Имя"
                           value={form.people[index].firstName}
                           onChange={(e) => dispatchToPeople({
                               type: 'setFirstName',
                               index,
                               firstName: e.target.value
                           })}/>
                </Form.Item>
                <Form.Item rules={[{ required: true, message: "Пожалуйста, введите Вашу фамилию!" }]}>
                    <Input placeholder="Фамилия"
                           value={form.people[index].lastName}
                           onChange={(e) => dispatchToPeople({
                               type: 'setLastName',
                               index,
                               lastName: e.target.value
                           })}/>
                </Form.Item>
                <Form.Item rules={[{ required: true, message: "Пожалуйста, введите Ваш возраст!" }]}>
                    <Select placeholder="Возрастная категория"
                            value={form.people[index].ageCategory}
                            options={ageCategories}
                            style={{ width: 120 }}
                            onSelect={ac => dispatchToPeople({
                                type: 'setAgeCategory',
                                index,
                                ageCategory: ac
                            })}/>
                </Form.Item>
                {form.people.length > 1 ? (
                    <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => dispatchToPeople({ type: 'remove', index })}
                    />
                ) : null}
            </Space>
        ))}
        <Form.Item key={'add'} style={{ alignSelf: 'center', width: '100%' }}>
            <Flex align="stretch" justify="center" style={{ width: '100%' }}>
                <Button
                    type="dashed"
                    onClick={() => dispatchToPeople({ type: 'add' })}
                    icon={<PlusOutlined/>}
                    style={{ width: '90%' }}
                >
                    Добавить человека
                </Button>
            </Flex>
        </Form.Item>
        <Form.Item>
            <Button type="primary"
                    htmlType="submit"
                    loading={submitRegistration.isPending}
                    style={{ width: '100%' }}
                    size="large"
                    disabled={!validateForm(form)}
                    onClick={() => onSubmit(form)}>Зарегистрироваться</Button>
        </Form.Item>
    </Form>;
}
