import { useMealService } from '../../../services/types.service';
import { useQuery } from '@tanstack/react-query';
import ShabbatInfo from './ShabbatInfo';
import { Alert, Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function FetchCurrentRegistrationsList() {
    const mealService = useMealService();
    const mealId = Number(useParams().id) ?? 0;
    const { data: registrations, status, error } = useQuery({
        queryKey: ['meal', 'registrations', mealId],
        queryFn: async () => {
            return mealService.getAllRegistrationsForMeal(Number(mealId));
        }
    });

    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    }
    return <ShabbatInfo mealId={mealId}
                        info={registrations}/>;
}
