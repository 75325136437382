import React, { ReactNode } from 'react';
import { createDependencyContext } from './context.service';
import { IMealService } from './meal.service';
import IPaymentService from './payment.service';
import IUserService from './user.service';
import IAuthService from './auth.service';
import { ILocaleService } from './locale.service';
import { ICommentsService } from './comments.service';
import { IMeService } from './me.service';
import { INotificationsService } from './notifications.service';
import { ICorrectionsService } from './corrections.service';
import { DiscountService, IDiscountService } from './discount.service';
import IAgeCategoryService from './age_category';

export const { context: MealServiceContext, useDependency: useMealService } = createDependencyContext<IMealService>();
export const {
    context: PaymentServiceContext,
    useDependency: usePaymentService
} = createDependencyContext<IPaymentService>();
export const { context: UserServiceContext, useDependency: useUserService } = createDependencyContext<IUserService>();
export const { context: AuthServiceContext, useDependency: useAuthService } = createDependencyContext<IAuthService>();
export const {
    context: LocaleServiceContext,
    useDependency: useLocaleService
} = createDependencyContext<ILocaleService>();

export const {
    context: CommentsServiceContext,
    useDependency: useCommentsService
} = createDependencyContext<ICommentsService>();

export const {
    context: MeServiceContext,
    useDependency: useMeService
} = createDependencyContext<IMeService>();

export const {
    context: NotificationsServiceContext,
    useDependency: useNotificationsService
} = createDependencyContext<INotificationsService>();

export const {
    context: CorrectionsServiceContext,
    useDependency: useCorrectionsService
} = createDependencyContext<ICorrectionsService>();

export const {
    context: DiscountServiceContext,
    useDependency: useDiscountService
} = createDependencyContext<IDiscountService>();

export const {
    context: AgeCategoriesServiceContext,
    useDependency: useAgeCategoryService
} = createDependencyContext<IAgeCategoryService>();

interface IDependencyContainerProps {
    mealService?: IMealService,
    paymentService?: IPaymentService,
    userService?: IUserService,
    authService?: IAuthService,
    localeService?: ILocaleService,
    commentsService?: ICommentsService,
    meService?: IMeService,
    notificationsService?: INotificationsService,
    correctionsService?: ICorrectionsService,
    discountService?: IDiscountService,
    ageCategoryService?: IAgeCategoryService,
    children: ReactNode
}

export function DependencyContainer(deps: IDependencyContainerProps) {
    return <MealServiceContext.Provider value={deps.mealService ?? null}>
        <PaymentServiceContext.Provider value={deps.paymentService ?? null}>
            <UserServiceContext.Provider value={deps.userService ?? null}>
                <AuthServiceContext.Provider value={deps.authService ?? null}>
                    <LocaleServiceContext.Provider value={deps.localeService ?? null}>
                        <CommentsServiceContext.Provider value={deps.commentsService ?? null}>
                            <MeServiceContext.Provider value={deps.meService ?? null}>
                                <NotificationsServiceContext.Provider value={deps.notificationsService ?? null}>
                                    <CorrectionsServiceContext.Provider
                                        value={deps.correctionsService ?? null}>
                                        <DiscountServiceContext.Provider value={deps.discountService ?? null}>
                                            <AgeCategoriesServiceContext.Provider value={deps.ageCategoryService ?? null}>
                                                {deps.children}
                                            </AgeCategoriesServiceContext.Provider>
                                        </DiscountServiceContext.Provider>
                                    </CorrectionsServiceContext.Provider>
                                </NotificationsServiceContext.Provider>
                            </MeServiceContext.Provider>
                        </CommentsServiceContext.Provider>
                    </LocaleServiceContext.Provider>
                </AuthServiceContext.Provider>
            </UserServiceContext.Provider>
        </PaymentServiceContext.Provider>
    </MealServiceContext.Provider>;
}
