import { Space, Typography } from 'antd';
import React from 'react';

interface TotalSeatsStatsProps {
    total: number;
}

export default function TotalSeatsStats({ total }: TotalSeatsStatsProps) {
    return <Space direction="vertical" size="small" align="center">
        <Typography.Text type="secondary">Всего зарегистрировано</Typography.Text>
        <Typography.Title level={3} style={{ margin: 0 }}>{total}</Typography.Title>
        <Typography.Text type="secondary">человек</Typography.Text>
    </Space>;
}
