import { Form, Input, InputNumber, Modal, Select } from 'antd';
import React from 'react';
import useModalForm from '../../../hooks/modal.hooks';
import NiceModal from '@ebay/nice-modal-react';
import { DiscountType } from '../../../services/discount.service';
import { useLocaleService } from '../../../services/types.service';
import { useTranslation } from 'react-i18next';

interface Props {
    onOk: (values: FormValues) => void;
}

interface FormValues {
    name: string;
    type: DiscountType;
    amount: number;
}

const discountTypes: DiscountType[] = ['PERCENTAGE', 'OVERRIDE'];

const AddDiscountModal = NiceModal.create(({ onOk }: Props) => {
    const { t } = useTranslation();
    const { form, submit, cancel, visible } = useModalForm<FormValues>({ onOk });
    const discountType = Form.useWatch('type', form);

    return <Modal
        centered
        title="Добавить скидку"
        onOk={() => submit()}
        onCancel={cancel}
        open={visible}
        destroyOnClose>
        <Form form={form}>
            <Form.Item name="name">
                <Input placeholder="Название"/>
            </Form.Item>
            <Form.Item name="type">
                <Select
                    placeholder="Тип скидки"
                    options={discountTypes.map(type => ({
                        value: type,
                        label: t(`DISCOUNT_${type}`)
                    }))}/>
            </Form.Item>
            <Form.Item name="amount">
                <InputNumber style={{ width: '100%' }}
                             placeholder={t(`DISCOUNT_${discountType}_VALUE`)}/>
            </Form.Item>
        </Form>
    </Modal>;
});

export default AddDiscountModal;
