import React, { ReactNode } from 'react';
import LoginPage from '../pages/login/LoginPage';
import { useAuth } from '../hooks/auth.hooks';

interface ProtectedRouteProps {
    children: ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
    const auth = useAuth();

    if (!auth.isAuthenticated) {
        return <LoginPage/>;
    }
    return <>{children}</>;
}
