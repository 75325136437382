import type { INetworkingService } from './networking.service';

export interface RegistrationComment {
    authorName: string,
    date: number,
    content: string,
}

export interface ICommentsService {
    getCommentsForRegistration(registrationId: number): Promise<Array<RegistrationComment>>;

    createComment(registrationId: number, content: string): Promise<RegistrationComment>;
}

export class CommentsService implements ICommentsService {
    constructor(private api: INetworkingService) {
    }

    getCommentsForRegistration(registrationId: number): Promise<RegistrationComment[]> {
        return this.api.get<RegistrationComment[]>(`/registrations/${registrationId}/comments`, {}, { withAuth: true });
    }

    createComment(registrationId: number, content: string): Promise<RegistrationComment> {
        return this.api.post(`/registrations/${registrationId}/comments`, { content: content }, { withAuth: true });
    }
}
