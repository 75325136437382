import React, { useState } from 'react';
import LoginForm from '../../components/login/LoginForm';
import { Button, Flex, Image, Typography } from 'antd';
import SignUpForm from '../../components/login/SignUpForm';

export default function LoginPage() {
    const [signUp, setSignUp] = useState<boolean>(false);

    return <Flex vertical align="center" justify="space-evenly">
        <Flex justify="center" align="center" style={{ width: '75%' }}>
            <Image src="/logo192.png"
                   preview={false}></Image>
        </Flex>
        <Flex vertical gap={2} style={{ justifySelf: 'center' }}>
            <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '0.5em' }}>
                {signUp ? 'Регистрация' : 'Вход'}
            </Typography.Title>
            <>
                {signUp ? <SignUpForm/> : <LoginForm/>}
                <Button
                    type="link"
                    onClick={() => setSignUp((prev) => !prev)}>
                    {signUp ? 'У меня уже есть учетная запись' : 'Создать новую учетную запись'}
                </Button>
            </>
        </Flex>
    </Flex>;
}
