import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMealService, usePaymentService } from '../services/types.service';
import { PaymentDescription } from '../services/payment.service';
import { useState } from 'react';
import { DetailedPriceInfo } from '../services/meal.service';
import _ from 'lodash';
import dayjs from 'dayjs';

export function useAddPaymentMutation() {
    const paymentService = usePaymentService();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ phone, payments }: {
            phone: string,
            payments: PaymentDescription[]
        }) => await paymentService.addPayment(phone, payments),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['debts'] });
            queryClient.invalidateQueries({ queryKey: ['current'] });
            queryClient.invalidateQueries({ queryKey: ['meal', 'registrations'] });
        }
    });
}
