import { useUserService } from '../../../services/types.service';
import { Alert, Spin } from 'antd';
import React from 'react';
import AllUsersInfo from './AllUsersInfo';
import { useQuery } from '@tanstack/react-query';

export default function FetchAllUsers() {
    const userService = useUserService();

    const { data: users, status, error } = useQuery({
        queryKey: ['users'],
        queryFn: async () => {
            return userService.getAllUsers();
        }
    });

    if (status === 'pending') {
        return <Spin/>;
    } else if (status === 'error') {
        return <Alert
            message={error.message}
            type="error"
        />;
    }
    return <AllUsersInfo users={users}/>;
}
