import { Button, Table } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Meal } from '../../../services/meal.service';
import dayjs from 'dayjs';

interface Props {
    meals: Array<Meal>;
}

interface FormValues {
    id: number;
    phone: string;
    firstName: string;
    lastName: string;
}

function getColumns(onClick: (id: number) => void): ColumnsType<Meal> {
    return [
        { title: 'Название', dataIndex: 'name', key: 'name' },
        {
            title: 'Дата регистрации',
            dataIndex: 'date',
            key: 'date',
            width: '60%',
            render: (value) => {
                console.dir(value);
                return dayjs(value).format('L');
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            width: '5%',
            align: 'center',
            render: (value) => <Button
                type="text"
                shape="circle"
                onClick={() => onClick(value.id)}
                icon={<EditOutlined/>}/>
        }
    ];
}

export default function MealsList({ meals }: Props) {
    const navigate = useNavigate();

    console.table(meals);

    return <Table
        columns={getColumns(id => {
            navigate(`${id}`);
        })}
        rowKey={meal => meal.id}
        dataSource={meals}
        scroll={{
            x: true
        }}/>;
}
