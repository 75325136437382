import { useAuthService } from '../services/types.service';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useAuth() {
    const authService = useAuthService();
    const queryClient = useQueryClient();
    const authQuery = useQuery({
        queryKey: ['me'],
        queryFn: () => authService.getUser()
    });

    return {
        user: authQuery.data,
        isAuthenticated: authQuery.isSuccess,
        invalidateAuth: () => queryClient.invalidateQueries({
            queryKey: ['me']
        })
    };
}

type RoleAction = 'READ' | 'WRITE';
type RoleTarget = 'USERS' | 'PAYMENTS' | 'REGISTRATIONS' | 'MEALS' | 'DISCOUNTS' | 'CATEGORIES';

export type Role = `${RoleAction}_${RoleTarget}` | 'ADMIN';

export function useRoleCheck(role: Role) {
    const { user } = useAuth();

    return (user && user.roles.some(r => r === role)) || false;
}
