import { RegistrationComment } from '../../../services/comments.service';
import React from 'react';
import { Flex, Typography } from 'antd';
import dayjs from 'dayjs';

interface Props {
    comment: RegistrationComment;
}

export default function CommentDisplay({ comment }: Props) {
    return <Flex vertical>
        <Flex align="center" gap={16}>
            <Typography.Text strong>{comment.authorName}</Typography.Text>
            <Typography.Text type="secondary" >{dayjs.unix(comment.date).format('LLL')}</Typography.Text>
        </Flex>
        <Typography.Paragraph>{comment.content}</Typography.Paragraph>
    </Flex>;
}
