import { INetworkingService } from './networking.service';

export type DiscountType = 'PERCENTAGE' | 'OVERRIDE';

export interface Discount {
    id: number;
    name: string;
    type: DiscountType;
    amount: number;
}

export interface IDiscountService {
    getDiscounts(): Promise<Discount[]>;
    createDiscount(name: string, type: DiscountType, amount: number): Promise<Discount>;
}

export class DiscountService implements IDiscountService {
    constructor(private api: INetworkingService) {}

    getDiscounts(): Promise<Discount[]> {
        return this.api.get(`/discount`, {}, { withAuth: true });
    }

    createDiscount(name: string, type: DiscountType, amount: number): Promise<Discount> {
        return this.api.post(`/discount`, {
            name, type, amount
        }, { withAuth: true });
    }
}
