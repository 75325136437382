import { Button, Flex, Result } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React from 'react';
import { useLocaleService } from '../../services/types.service';

export default function RegistrationResult() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const localeService = useLocaleService();
    const isSuccess = searchParams.get('success') === 'true';
    const errorMessage = searchParams.get('error');

    return <Flex align="center"
                 justify="center"
                 style={{ height: '100%' }}>
        <Flex justify="center" align="center" vertical gap={0}>
            {isSuccess ? <Result
                status="success"
                title="Вы успешно зарегистрировались на трапезу!"
            /> : <Result
                status="error"
                title="Во время регистрации произошла ошибка"
                subTitle={localeService.getMessage(errorMessage ?? "") ?? "Ошибка"}
            />}
            <Flex>
                <Button type="default" onClick={() => navigate('/')}>← К регистрации</Button>
            </Flex>
        </Flex>
    </Flex>;
}
