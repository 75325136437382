import React from 'react';
import { Flex, Typography } from 'antd';
import FetchCurrentDebts from '../../components/management/debts/FetchCurrentDebts';

export default function CurrentDebtsInfoPage() {
    return <Flex vertical>
        <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '0.5em' }}>Все непокрытые
            оплаты</Typography.Title>
        <FetchCurrentDebts/>
    </Flex>;
}
