import type { INetworkingService } from './networking.service';

export interface FullDebtInfo {
    user: User;
    total: number;
    debts: Array<{
        amount: number;
        date: number;
    }>;
}

export interface User {
    id: number;
    phone: string;
    firstName: string;
    lastName: string;
}

export default interface IUserService {
    getAllCurrentDebts(): Promise<Array<FullDebtInfo>>;

    getAllUsers(): Promise<Array<User>>;

    createUser(phone: string, firstName: string, lastName: string): Promise<User>;

    updateUser(id: number, phone: string, firstName: string, lastName: string): Promise<User>;
}

export class UserService implements IUserService {
    constructor(private api: INetworkingService) {}

    public async getAllCurrentDebts(): Promise<Array<FullDebtInfo>> {
        return await this.api.get('/users/debts', {}, { withAuth: true });
    }

    public async getAllUsers(): Promise<User[]> {
        return await this.api.get('/users', {}, { withAuth: true });
    }

    public async createUser(phone: string, firstName: string, lastName: string): Promise<User> {
        return await this.api.post('/users', {
            phone: phone.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim()
        }, { withAuth: true });
    }

    public async updateUser(id: number, phone: string, firstName: string, lastName: string): Promise<User> {
        return await this.api.put(`/users/${id}`, {
            id, phone: phone.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim()
        }, { withAuth: true });
    }
}
