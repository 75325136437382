export interface ILocaleService {
    getMessage(key: string): string | undefined;
}

export class LocaleService implements ILocaleService {
    private messages;

    constructor() {
        this.messages = new Map<string, string>();
        this.messages.set('ERR_INCORRECT_USER_DATA', 'Некорректные данные! Пожалуйста, перепроверьте свои данные');
        this.messages.set('DISCOUNT_PERCENTAGE', 'Процент');
        this.messages.set('DISCOUNT_OVERRIDE', 'Замена цены');
        this.messages.set('DISCOUNT_PERCENTAGE_VALUE', 'Процент');
        this.messages.set('DISCOUNT_OVERRIDE_VALUE', 'Новая цена');
    }

    getMessage(key: string): string | undefined {
        return this.messages.get(key);
    }
}
