export default interface IStorageService {
    get(key: string): string | null;

    set(key: string, value: string | null): void;

    remove(key: string): void;

    clear(): void;
}

export class LocalStorageService implements IStorageService {
    public get(key: string): string | null {
        return localStorage.getItem(key);
    }

    public set(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }
}
