import { Flex, Typography } from 'antd';
import React, { ReactNode, useCallback } from 'react';
import { RightOutlined } from '@ant-design/icons';

interface Props {
    title: string;
    withArrow?: boolean;
    onClick?: () => void;
    children?: ReactNode;
    titleColor?: string;
    backgroundColor?: string;
}

export default function DashboardSection({
                                             title,
                                             withArrow,
                                             onClick,
                                             children,
                                             titleColor = '#000',
                                             backgroundColor = '#fff8f8'
                                         }: Props) {
    const isClickable = onClick !== undefined;
    const handleClick = useCallback(() => {
        if (onClick === undefined) {} else {
            onClick();
        }
    }, [onClick]);


    return <div
        style={{
            cursor: isClickable ? 'pointer' : 'auto',
            background: backgroundColor,
            borderRadius: 16,
            padding: '16px'
        }}
        onClick={handleClick}>
        <Flex vertical gap={4}>
            <Flex justify="space-between" align="center">
                <Typography.Title level={4} style={{ margin: 0, color: titleColor }}>{title}</Typography.Title>
                {withArrow ? <RightOutlined style={{ color: titleColor }}/> : null}
            </Flex>
            {children}
        </Flex>
    </div>;
}
