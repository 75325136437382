import type { INetworkingService } from './networking.service';

export interface PaymentDescription {
    mealId: number,
    amount: number
}

export default interface IPaymentService {
    addPayment(phone: string, payments: PaymentDescription[]): Promise<void>;
}

export class PaymentService implements IPaymentService {
    constructor(private api: INetworkingService) {}

    public addPayment(phone: string, payments: PaymentDescription[]): Promise<void> {
        return this.api.post('/payments', {
            phoneNumber: phone.trim(),
            payments
        }, { withAuth: true });
    }
}
