import { Form, Input, InputNumber, Modal } from 'antd';
import React from 'react';
import useModalForm from '../../../hooks/modal.hooks';
import NiceModal from '@ebay/nice-modal-react';

interface Props {
    onOk: (values: FormValues) => void;
}

interface FormValues {
    name: string;
    basePrice: number;
}

const AddAgeCategoryModal = NiceModal.create(({ onOk }: Props) => {
    const { form, submit, cancel, visible } = useModalForm<FormValues>({ onOk });

    return <Modal
        centered
        title="Добавить возрастную категорию"
        onOk={() => submit()}
        onCancel={cancel}
        open={visible}
        destroyOnClose>
        <Form form={form}>
            <Form.Item name="name">
                <Input placeholder="Название"/>
            </Form.Item>
            <Form.Item name="basePrice">
                <InputNumber style={{ width: '100%' }}
                             placeholder="Стандартная цена"/>
            </Form.Item>
        </Form>
    </Modal>;
});

export default AddAgeCategoryModal;
