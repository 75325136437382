import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useAgeCategoryService } from '../services/types.service';

export default function useAgeCategoryOptions() {
    const ageCategoriesService = useAgeCategoryService();

    const { data: ageCategories } = useQuery({
        queryKey: ['ageCategories'],
        queryFn: async () => {
            return ageCategoriesService.getAllCategories();
        },
    });

    const options = ageCategories?.map(ac => ({
        label: ac.name,
        value: ac.id
    }));

    return options;
}
