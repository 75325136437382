import type { INetworkingService } from './networking.service';
import { User } from './user.service';
import _ from 'lodash';

export interface Meal {
    id: number;
    name: string;
    date: string;
    people: Array<PersonRegistration>;
}

export interface DetailedPriceInfo {
    basePrice: {
        people: Array<{ name: string, price: number }>
    };
    debt: Array<{
        amount: number
        date: number
    }>;
    totalPrice: number;
}

export interface CorrectionInfo {
    adults: number;
    juniors: number;
    kids: number;
    price: number;
}

export interface PersonRegistration {
    firstName: string;
    lastName: string;
    ageCategory: number;
}

export interface PersonRegistrationInfo {
    id: number;
    name: string;
    price: number;
    enabled: boolean;
    discountId: number;
}

export interface UserRegistrationInfo {
    id: number;
    phone: string;
    firstName: string;
    lastName: string;
    date: number;
    people: Array<PersonRegistrationInfo>;
    startingDebt: number;
    commentsNumber: number;
    price: number;
    totalDebt: number;
    totalPayment: number;
}

export interface RegistrationInfo {
    id: number;
    user: User;
    adults: number;
    juniors: number;
    kids: number;
    date: number;
    price: number;
    totalToPay: number;
}

export interface AllRegistrationsInfo {
    registrations: Array<UserRegistrationInfo>;
    totalSeats: number;
    totalPayment: number;
    totalPrice: number;
}

export interface IMealService {
    getPrice(people: Array<PersonRegistration>): Promise<DetailedPriceInfo>;
    registerForMeal(mealId: number, people: Array<PersonRegistration>): Promise<RegistrationInfo>;
    getAllRegistrationsForMeal(mealId: number): Promise<AllRegistrationsInfo>;
    getAllMeals(): Promise<Array<Meal>>;
    getFutureMeals(): Promise<Array<Meal>>;
    getExcelRegistrationsFile(mealId: number): Promise<Blob>;
    createMeal(name: string, date: string): Promise<Meal>;
    getMealById(id: number): Promise<Meal>;
    togglePersonRegistration(id: number): Promise<void>;
    setRegistrationDiscount(registrationId: number, discountId: number): Promise<void>;
}

export default class MealService implements IMealService {
    constructor(private api: INetworkingService) {
    }

    public async getPrice(people: Array<PersonRegistration>): Promise<DetailedPriceInfo> {
        return this.api.post('/meals/preview', {
            people: _.chain(people)
                .filter(p => p !== undefined
                    && p.firstName !== ''
                    && p.lastName !== ''
                    && p.ageCategory >= 0)
                .map(p => ({ ...p, name: `${p.firstName} ${p.lastName}` }))
                .value()
        }, { withAuth: true });
    }

    public async registerForMeal(mealId: number, people: Array<PersonRegistration>): Promise<RegistrationInfo> {
        return await this.api.post('/meals/register', {
            mealId, people: people.map(p => ({ ...p, name: `${p.firstName} ${p.lastName}` }))
        }, { withAuth: true });
    }

    public async getAllRegistrationsForMeal(mealId: number): Promise<AllRegistrationsInfo> {
        return await this.api.get(`/meals/${mealId}/registrations`, {}, { withAuth: true });
    }

    async getExcelRegistrationsFile(mealId: number): Promise<Blob> {
        return this.api.getBlob(`/meals/${mealId}/xlsx`, {}, { withAuth: true });
    }

    public getAllMeals(): Promise<Array<Meal>> {
        return this.api.get('/meals', {}, { withAuth: true });
    }

    public getFutureMeals(): Promise<Array<Meal>> {
        return this.api.get('/meals/future', {}, { withAuth: true });
    }

    public createMeal(name: string, date: string): Promise<Meal> {
        return this.api.post('/meals', { name, date }, { withAuth: true });
    }

    public async getMealById(id: number): Promise<Meal> {
        return this.api.get(`/meals/${id}`, {}, { withAuth: true });
    }

    public async togglePersonRegistration(id: number): Promise<void> {
        return this.api.post(`/people/${id}/toggle`, {}, { withAuth: true });
    }

    public setRegistrationDiscount(registrationId: number, discountId: number): Promise<void> {
        return this.api.post(`/people/${registrationId}/discount`, { discountId }, { withAuth: true });
    }
}
