import { Button, Flex } from 'antd';
import React from 'react';
import NavBar, { NavBarItem } from '../../components/NavBar';
import { Outlet } from 'react-router-dom';
import FloatingMenuButton from '../../components/management/FloatingMenuButton';
import { useAuthService } from '../../services/types.service';
import { LogoutOutlined } from '@ant-design/icons';
import i18next from 'i18next';

const navBarItems: NavBarItem[] = [
    {
        name: 'NAVBAR_MEALS',
        href: 'meals'
    },
    {
        name: 'NAVBAR_DEBTS',
        href: 'debts'
    },
    {
        name: 'NAVBAR_USERS',
        href: 'users'
    },
    {
        name: 'NAVBAR_CATEGORIES',
        href: 'categories',
    },
    {
        name: 'NAVBAR_DISCOUNTS',
        href: 'discounts'
    }
];

export default function AdminPanel() {
    const authService = useAuthService();

    return <Flex vertical gap="8px">
        <Flex align="center" justify="space-between">
            <NavBar items={navBarItems}/>
            <Button type="primary"
                    style={{
                        margin: '8px 16px',
                    }}
                    icon={<LogoutOutlined/>}
                    onClick={() => authService.logout()}/>
        </Flex>
        <Outlet/>
        <FloatingMenuButton/>
    </Flex>;
}
