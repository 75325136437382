import React from 'react';
import './App.css';
import { ConfigProvider, Layout, ThemeConfig } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MealService from './services/meal.service';
import { AxiosNetworkingService } from './services/networking.service';
import axios from 'axios';
import { DependencyContainer } from './services/types.service';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RegistrationPage from './pages/main/RegistrationPage';
import RegistrationResult from './pages/main/RegistrationResult';
import CurrentShabbatInfoPage from './pages/admin/CurrentShabbatInfoPage';
import AdminPanel from './pages/admin/AdminPanel';
import { PaymentService } from './services/payment.service';
import { UserService } from './services/user.service';
import CurrentDebtsInfoPage from './pages/admin/CurrentDebtsInfoPage';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthService } from './services/auth.service';
import { LocalStorageService } from './services/storage.service';
import { LocaleService } from './services/locale.service';
import AllUsersInfoPage from './pages/admin/AllUsersInfoPage';
import NiceModal from '@ebay/nice-modal-react';
import MealsListPage from './pages/admin/MealListPage';
import { CommentsService } from './services/comments.service';
import * as Sentry from '@sentry/react';
import UserDashboard from './pages/main/UserDashboard';
import { MeService } from './services/me.service';
import Notifications from './components/Notifications';
import { NotificationsService } from './services/notifications.service';
import { SnackbarProvider } from 'notistack';
import { CorrectionsService } from './services/corrections.service';
import { DiscountService } from './services/discount.service';
import DiscountListPage from './pages/admin/DiscountListPage';
import './init_translation';
import { AgeCategoryService } from './services/age_category';
import AgeCategoriesListPage from './pages/admin/AgeCategoriesListPage';

console.log(process.env.REACT_APP_BACKEND_URL);

const queryClient = new QueryClient();
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});
const storageService = new LocalStorageService();
const networkingService = new AxiosNetworkingService(axiosClient, storageService);
const mealService = new MealService(networkingService);
const paymentService = new PaymentService(networkingService);
const userService = new UserService(networkingService);
const authService = new AuthService(networkingService, storageService, queryClient);
const localeService = new LocaleService();
const commentsService = new CommentsService(networkingService);
const meService = new MeService(networkingService);
const notificationsService = new NotificationsService();
const correctionsService = new CorrectionsService(networkingService);
const discountService = new DiscountService(networkingService);
const ageCategoryService = new AgeCategoryService(networkingService);

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
    {
        path: '/',
        element: <UserDashboard/>
    },
    {
        path: '/registration/:id',
        element: <RegistrationPage/>
    }, {
        path: '/result',
        element: <RegistrationResult/>
    }, {
        path: '/admin',
        element: <AdminPanel/>,
        children: [{
            path: 'meals',
            element: <MealsListPage/>,
        }, {
            path: 'meals/:id',
            element: <CurrentShabbatInfoPage/>
        }, {
            path: 'debts',
            element: <CurrentDebtsInfoPage/>
        }, {
            path: 'users',
            element: <AllUsersInfoPage/>
        }, {
            path: 'categories',
            element: <AgeCategoriesListPage/>
        }, {
            path: 'discounts',
            element: <DiscountListPage/>
        }]
    }]);

const theme: ThemeConfig = {
    components: {
        Layout: {
            // bodyBg: '#7ab1ff',
        }
    },
    token: {
        colorPrimary: '#831330',
        colorInfo: '#831330'
    }
};

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <DependencyContainer authService={authService} mealService={mealService} paymentService={paymentService}
                                 userService={userService} localeService={localeService}
                                 commentsService={commentsService} meService={meService}
                                 notificationsService={notificationsService} correctionsService={correctionsService}
                                 discountService={discountService} ageCategoryService={ageCategoryService}>
                <NiceModal.Provider>
                    <ConfigProvider theme={theme}>
                        <Layout style={{ height: '100vh' }}>
                            <Sentry.ErrorBoundary showDialog>
                                <Notifications/>
                                <SnackbarProvider anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}>
                                    <ProtectedRoute>
                                        <RouterProvider router={router}/>
                                    </ProtectedRoute>
                                </SnackbarProvider>
                            </Sentry.ErrorBoundary>
                        </Layout>
                    </ConfigProvider>
                </NiceModal.Provider>
            </DependencyContainer>
        </QueryClientProvider>
    );
}

export default App;
