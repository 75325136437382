import { User } from '../../../services/user.service';
import { Button, Table } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import UpdateUserModal from './UpdateUserModal';
import { useUserService } from '../../../services/types.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRoleCheck } from '../../../hooks/auth.hooks';

interface Props {
    users: Array<User>;
}

interface FormValues {
    id: number;
    phone: string;
    firstName: string;
    lastName: string;
}

function getColumns(onEditFinished: (values: FormValues) => void, hasEditRole: boolean): ColumnsType<User> {
    let columns: ColumnsType<User> = [
        { title: 'Телефон', dataIndex: 'phone', key: 'phone' },
        { title: 'Имя', dataIndex: 'firstName', key: 'firstName' },
        { title: 'Фамилия', dataIndex: 'lastName', key: 'lastName' }
    ];
    if (hasEditRole) {
        columns.push({
            title: '',
            dataIndex: '',
            key: 'x',
            width: '5%',
            align: 'center',
            render: (value) => <Button
                type="text"
                shape="circle"
                onClick={() => NiceModal.show(UpdateUserModal, { user: value, onOk: onEditFinished })}
                icon={<EditOutlined/>}/>,
        });
    }
    return columns;
}

export default function AllUsersInfo({ users }: Props) {
    const userService = useUserService();
    const queryClient = useQueryClient();
    const canEditUsers = useRoleCheck('WRITE_USERS');

    const updateUserMutation = useMutation({
        mutationFn: async ({
                               id,
                               phone,
                               firstName,
                               lastName
                           }: FormValues) => await userService.updateUser(id, phone, firstName, lastName),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['users'] });
        }
    });

    return <Table columns={getColumns(values => {
        console.dir(values);
        updateUserMutation.mutate(values);
    }, canEditUsers)}
                  rowKey={user => user.phone}
                  dataSource={users}
                  scroll={{
                      x: true
                  }}/>;
}
