import { Flex, Typography } from 'antd';
import React from 'react';
import FetchAllAgeCategories from '../../components/management/age_categories/FetchAllAgeCategories';

export default function AgeCategoriesListPage() {
    return <Flex vertical>
        <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '0.5em' }}>Возрастные категории</Typography.Title>
        <FetchAllAgeCategories/>
    </Flex>;
}
