import { Flex, Typography } from 'antd';
import React from 'react';
import FetchAllMeals from '../../components/management/meals/FetchAllMeals';

export default function MealsListPage() {
    return <Flex vertical>
        <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '0.5em' }}>Все трапезы</Typography.Title>
        <FetchAllMeals/>
    </Flex>;
}
