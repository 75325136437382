import { useAgeCategoryService } from '../../../services/types.service';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FloatButton } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import AddAgeCategoryModal from './AddAgeCategoryModal';

export default function AddAgeCategoryButton() {
    const ageCategoryService = useAgeCategoryService();
    const queryClient = useQueryClient();

    const addAgeCategoryMutation = useMutation({
        mutationFn: async ({ name, basePrice }: {
            name: string,
            basePrice: number
        }) => await ageCategoryService.createNewCategory(name, basePrice),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['ageCategories'] });
        }
    });

    return <>
        <FloatButton
            icon={<TeamOutlined />}
            onClick={() => NiceModal.show(AddAgeCategoryModal, {
                onOk: (values) => addAgeCategoryMutation.mutate(values)
            })}/>
    </>;
}
