import { INetworkingService } from './networking.service';

interface MyDebtInfo {
    total: number;
    debts: Array<{
        amount: number;
        date: number;
    }>;
}

export interface IMeService {
    getDebt(): Promise<MyDebtInfo>;
}

export class MeService implements IMeService {
    constructor(private api: INetworkingService) {}

    async getDebt(): Promise<MyDebtInfo> {
        return await this.api.get('/me/debts', {}, { withAuth: true });
    }
}
