import { useMealService } from '../../../services/types.service';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FloatButton } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import AddMealModal from './AddMealModal';
import NiceModal from '@ebay/nice-modal-react';

export default function AddMealButton() {
    const mealService = useMealService();
    const queryClient = useQueryClient();

    const addMealMutation = useMutation({
        mutationFn: async ({ name, date }: {
            name: string,
            date: string,
        }) => await mealService.createMeal(name, date),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['meals'] });
        }
    });

    return <>
        <FloatButton
            icon={<CalendarOutlined />}
            onClick={() => NiceModal.show(AddMealModal, {
                onOk: (values) => addMealMutation.mutate({ name: values.name, date: values.date })
            })}/>
    </>;
}
