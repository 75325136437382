import type { INetworkingService } from './networking.service';
import type IStorageService from './storage.service';
import { QueryClient } from '@tanstack/react-query';

interface SignUpRequest {
    phone: string;
    password: string;
    firstName: string;
    lastName: string;
}

interface LoginRequest {
    phone: string;
    password: string;
}

export interface LoginResponse {
    token: string;
}

export interface GetUserResponse {
    name: string;
    phone: string;
    roles: string[];
}

export default interface IAuthService {
    signUp(phone: string, password: string, firstName: string, lastName: string): Promise<LoginResponse>;

    login(phone: string, password: string): Promise<LoginResponse>;

    getUser(): Promise<GetUserResponse>;

    logout(): void;
}

export class AuthService implements IAuthService {
    constructor(private api: INetworkingService, private storage: IStorageService, private queryClient: QueryClient) {}

    public getUser(): Promise<GetUserResponse> {
        return this.api.get('/me', {}, { withAuth: true });
    }

    public async signUp(phone: string, password: string, firstName: string, lastName: string): Promise<LoginResponse> {
        const response = await this.api.post<LoginResponse, SignUpRequest>('/signup', {
            phone, password, firstName, lastName
        }, { withAuth: false });
        this.storage.set('token', response.token);
        await this.queryClient.invalidateQueries({
            queryKey: ['me']
        });
        return response;
    }

    public async login(phone: string, password: string): Promise<LoginResponse> {
        const response = await this.api.post<LoginResponse, LoginRequest>('/login', {
            phone: phone, password
        }, { withAuth: false });
        this.storage.set('token', response.token);
        await this.queryClient.invalidateQueries({
            queryKey: ['me']
        });
        return response;
    }

    public logout(): void {
        this.storage.remove('token');
        this.queryClient.invalidateQueries({
            queryKey: ['me']
        });
    }
}
